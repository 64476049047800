import './export-job-channels-card.css';
import { ExportJobViewInfoQuery } from '$typings/graphql-codegen';
import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React, { memo, FC } from 'react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ensureDate, formatDateTime } from '$lib/dateHelpers';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';

interface IExportJobChannelsCardProps {
  exportJob?: ExportJobViewInfoQuery['exportJob'];
}

type ExportedSiteChannelType = NonNullable<
  ExportJobViewInfoQuery['exportJob']
>['exportedSiteChannels'][0];

const ExportJobChannelsCard: FC<IExportJobChannelsCardProps> = ({
  exportJob
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const defaultFields = [
    'SiteId',
    'SiteAlias',
    'ChannelId',
    'ChannelAlias',
    'ShipTo',
    'Product'
  ];
  const getRows = (channel: ExportedSiteChannelType, field: string) => {
    {
      switch (field) {
        case 'SiteName':
          return channel?.controller.site?.name;
        case 'ControllerSerial':
          return channel?.controller.serial;
        case 'ChannelId':
          return channel?.channelId;
        case 'ShipTo':
          return channel?.tankDetails?.shipTo;
        case 'SampleTime':
          return formatDateTime(
            ensureDate(channel!.lastSampleTime!),
            'dd.MM.yyyy HH:mm',
            undefined
          );
        case 'Value':
          return channel?.lastSample;
        case 'Unit':
          return channel?.unit?.symbol;
        case 'ChannelName':
          return channel?.code;
        case 'ChannelAlias':
          return channel?.alias;
        case 'SiteId':
          return channel?.controller.site?.siteId;
        case 'SiteAlias':
          return channel?.controller.site?.alias;
        case 'CustomerReference':
          return channel?.controller.site?.customerRef;
        case 'ProjectCode':
          return channel?.controller.site?.projectCode;
        case 'Product':
          return channel?.product?.name;
        case 'CustomerProductRef':
          return channel?.tankDetails?.customerProductRef;
        default:
          return 'Unknown';
      }
    }
  };

  const VirtuosoTableComponents: TableComponents = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer {...props} ref={ref} />
    )),
    Table: props => <Table {...props} />,
    TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableHead {...props} ref={ref} />
    )),
    TableRow,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    ))
  };

  return (
    <Card className="flex h-full">
      <CardContent className="export-job-channels-card-table-wrapper flex flex-col grow">
        <CardHeader
          sx={{ paddingLeft: 0, paddingTop: 0 }}
          titleTypographyProps={{ fontSize: '1.3rem' }}
          title={`${t('UI_Admin_ExportJob_Edit_Channels')} (${exportJob
            ?.exportedSiteChannels.length ?? '0'})`}
        />
        {exportJob ? (
          <TableVirtuoso
            className="grow"
            style={{ minHeight: 0 }}
            data={exportJob?.exportedSiteChannels}
            components={VirtuosoTableComponents}
            itemContent={(_, channel: ExportedSiteChannelType) => (
              <>
                {exportJob?.fields?.length == 0
                  ? defaultFields.map(field => (
                      <TableCell key={field}>
                        {getRows(channel, field)}
                      </TableCell>
                    ))
                  : exportJob?.fields?.map(field => (
                      <TableCell key={field?.exportFieldId}>
                        {getRows(channel, field!.name!)}
                      </TableCell>
                    ))}
              </>
            )}
            fixedHeaderContent={() => (
              <TableRow>
                {exportJob?.fields?.length == 0
                  ? defaultFields.map(field => (
                      <TableCell key={field}>{field}</TableCell>
                    ))
                  : exportJob?.fields?.map(field => (
                      <TableCell key={field?.exportFieldId}>
                        {field?.name}
                      </TableCell>
                    ))}
              </TableRow>
            )}
          />
        ) : (
          <SimpleTable>
            {Array.from({ length: 10 }).map((_, index) => (
              <React.Fragment key={index}>
                <SimpleTableRow>
                  <SimpleTableCell>
                    <Skeleton />
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Skeleton />
                  </SimpleTableCell>
                </SimpleTableRow>
              </React.Fragment>
            ))}
          </SimpleTable>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(ExportJobChannelsCard);
