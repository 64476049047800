import { HistoryList } from '$components/history-list/history-list/history-list.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import { LatestExportJobRunsDocument } from '$typings/graphql-codegen';
import { Card, CardContent, CardHeader } from '@mui/material';
import React, { memo, FC, useState, useEffect } from 'react';
import Button from '$components/buttons/button.react';

interface IExportJobLatestRunsCardProps {
  exportJobId: string;
}

const ExportJobLatestRunsCard: FC<IExportJobLatestRunsCardProps> = ({
  exportJobId
}) => {
  const AMOUNT_OF_RUNS_TO_LOAD = 10;
  const [t] = useCaseInsensitiveTranslation();
  const [amountToLoad, setAmountToLoad] = useState(AMOUNT_OF_RUNS_TO_LOAD);
  const { data, loading } = useQuery(LatestExportJobRunsDocument, {
    exportJobId: ensureNumber(exportJobId),
    amount: amountToLoad
  });

  const loadMoreRef = React.createRef<HTMLDivElement>()

  const scrollToBottom = () => {
    //Only scroll when we have more than 10 items
    if (!loadMoreRef.current || amountToLoad <= 10) return; 

    setTimeout(() => {      
      loadMoreRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 10);
  }

  //Scroll to bottom of export runs when more are loaded
  useEffect(() => {
    scrollToBottom();
  }, [data, loading]);

  return (
    <Card>
      <CardContent className="flex flex-col">
        <CardHeader
          sx={{ paddingLeft: 0, paddingTop: 0 }}
          title={t('UI_ExportJobRuns_Latest_Runs')}
          titleTypographyProps={{ fontSize: '1.3rem' }}
        />
        {data && (
          <>
            <p className="pb-4">
              {data.exportJobRuns.length == 0
                ? t('UI_Admin_ExportJob_RunHistory_None')
                : t('UI_Admin_ExportJob_Run_Fetched', {
                    count: data.exportJobRuns.length
                  })}
            </p>
            <div className="w-full overflow-y-auto" style={{ maxHeight: '1000px' }}>
              <HistoryList
                items={data!.exportJobRuns.map(run => ({
                  text: [run.result ?? ''],
                  timeStamp: run.timestamp,
                  externalLinks: run.sasReadUrl
                    ? [{ url: run.sasReadUrl, text: 'Download' }]
                    : []
                }))}
              />
              {data!.exportJobRuns.length >= AMOUNT_OF_RUNS_TO_LOAD && (
                <div ref={loadMoreRef} style={{ textAlign: 'center' }}>                                
                  <p className='pt-4'>
                    {data.exportJobRuns.length == 0
                      ? t('UI_Admin_ExportJob_RunHistory_None')
                      : t('UI_Admin_ExportJob_Run_Fetched', {
                          count: data.exportJobRuns.length
                    })}
                  </p>

                  <Button type="submit" variant="tertiary" onClick={() => {
                    setAmountToLoad(amountToLoad + AMOUNT_OF_RUNS_TO_LOAD);
                  }}>{t('UI_ExportJobRuns_Load_More')}</Button>                
                </div>
              )}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(ExportJobLatestRunsCard);