import { MuiModalCard } from '$components/modals/mui-modal-card/mui-modal-card';
import React, { memo, FC } from 'react';
import ExportJobForm from './export-job-form';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface IExportJobCreateModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ExportJobCreateModal: FC<IExportJobCreateModalProps> = ({
  open,
  setOpen
}) => {
  const [t] = useCaseInsensitiveTranslation();

  const onCreateSuccess = () => {
    setOpen(false);
  };

  return (
    <MuiModalCard
      open={open}
      aria-labelledby="export-job-add-modal"
      aria-describedby="modal-modal-description"
      headerText={t('UI_ExportJobCreate_Create_Job')}
    >
      <div className="p-4">
        <ExportJobForm
          onCancel={() => setOpen(false)}
          onUpsertSuccess={onCreateSuccess}
        />
      </div>
    </MuiModalCard>
  );
};

export default memo(ExportJobCreateModal);
