import { Card, CardContent, CardHeader } from '@mui/material';
import React, { memo, FC } from 'react';
import ExportJobForm from './export-job-form';
import { ExportJobViewInfoQuery } from '$typings/graphql-codegen';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface IExportJobEditCardProps {
  exportJob?: ExportJobViewInfoQuery['exportJob'];
  onUpsertSuccess: () => void;
  onCancel: () => void;
}

const ExportJobEditCard: FC<IExportJobEditCardProps> = ({
  exportJob,
  onUpsertSuccess,
  onCancel
}) => {
  const [t] = useCaseInsensitiveTranslation();
  return (
    <Card>
      <CardContent>
        <CardHeader
          sx={{ paddingLeft: 0, paddingTop: 0 }}
          title={t('UI_Admin_ExportJob_Edit_Title')}
          titleTypographyProps={{ fontSize: '1.3rem' }}
        />
        <ExportJobForm
          layout="fixed"
          exportJob={exportJob}
          onCancel={onCancel}
          onUpsertSuccess={onUpsertSuccess}
        />
      </CardContent>
    </Card>
  );
};

export default memo(ExportJobEditCard);
