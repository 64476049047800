import './export-details.css';
import React, { FC, memo, useState } from 'react';
import ExportJobDetailsCard from '../components/export-job-details-card';
import ExportJobFieldsCard from '../components/export-job-fields-card';
import ExportJobLatestRunsCard from '../components/export-job-latest-runs-card';
import ExportJobChannelsCard from '../components/export-job-channels-card';
import {
  ExportJobViewInfoDocument,
  RunExportDocument
} from '$typings/graphql-codegen';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { useNavigate, useParams } from 'react-router-dom';
import { ensureNumber } from '$lib/numberHelpers';
import { Button, Card, CardContent } from '@mui/material';
import ExportJobEditCard from '../components/export-job-edit-card';
import ExportJobFieldsEditCard from '../components/export-job-fields-edit-card';
import { revalidateAllActiveQueries } from '$pages/common/GraphQLFetcher';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { NotificationService } from '$services/notificationService';

interface IExportDetailsProps {
  aureliaNotificationService: NotificationService;
}

const ExportDetails: FC<IExportDetailsProps> = ({
  aureliaNotificationService
}) => {
  const nav = useNavigate();
  const [t] = useCaseInsensitiveTranslation();
  const { exportId } = useParams<string>();
  const { data } = useQuery(ExportJobViewInfoDocument, {
    exportJobId: ensureNumber(exportId!)
  });
  const [isEditDetails, setIsEditDetails] = useState<boolean>(false);
  const [isEditFields, setIsEditFields] = useState<boolean>(false);

  const onRefreshClick = () => {
    revalidateAllActiveQueries();
  };

  const onRunNowClick = async () => {
    if (!data?.exportJob) return;
    await mutate(
      RunExportDocument,
      {
        exportJobId: ensureNumber(data.exportJob.exportJobId)
      },
      false,
      res => {
        if (res.runExport.success) {
          aureliaNotificationService.notify({
            type: 'CUSTOM',
            level: 'info',
            text:
              'The export job has been scheduled and will run shortly, you will need to refresh the page to see the result when its finished',
            timestamp: new Date().toString(),
            acknowledged: false
          });
        } else {
          aureliaNotificationService.notify({
            type: 'CUSTOM',
            level: 'error',
            text:
              res.runExport?.message ||
              'Something went wrong, could not run export job',
            timestamp: new Date().toString(),
            acknowledged: false
          });
        }
      }
    );
  };

  return (
    <div className="flex flex-col gap-4 p-2 grow">
      <div>
        <Card>
          <CardContent className="flex gap-2 items-center">
            <Button onClick={() => nav('/export')} variant="outlined">
              {t('UI_ExportJobDetails_Return')}
            </Button>
            <Button onClick={onRunNowClick} variant="outlined">
              {t('UI_ExportJobDetails_Run_Now')}
            </Button>
            <Button onClick={onRefreshClick} variant="outlined">
              {t('UI_ExportJobDetails_Refresh')}
            </Button>
          </CardContent>
        </Card>
      </div>

      <div className="flex grow flex-row gap-4">
        <div className="flex flex-col grow gap-4">
          <div className=" flex flex-row gap-4">
            <div className=" export-details-card-wrapper">
              {isEditDetails ? (
                <ExportJobEditCard
                  exportJob={data?.exportJob}
                  onCancel={() => setIsEditDetails(false)}
                  onUpsertSuccess={() => setIsEditDetails(false)}
                />
              ) : (
                <ExportJobDetailsCard
                  onEditClick={() => setIsEditDetails(true)}
                  exportJob={data?.exportJob}
                />
              )}
            </div>
            <div className="export-fields-card-wrapper">
              {isEditFields ? (
                <ExportJobFieldsEditCard
                  exportJob={data?.exportJob}
                  exportFields={data?.exportFields}
                  onSaveSuccess={() => setIsEditFields(false)}
                  onCancel={() => setIsEditFields(false)}
                />
              ) : (
                <ExportJobFieldsCard
                  onEditClick={() => setIsEditFields(true)}
                  exportJob={data?.exportJob}
                />
              )}
            </div>
          </div>

          <div className="export-channels-card-wrapper grow">
            <ExportJobChannelsCard exportJob={data?.exportJob} />
          </div>
        </div>

        <div className="grow" style={{ maxWidth: '400px', minWidth: '250px' }}>
          {data && data.exportJob && (
            <ExportJobLatestRunsCard
              exportJobId={data.exportJob.exportJobId!}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ExportDetails);
