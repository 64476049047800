import Icon from '$components/icons/icon/icon.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ExportJobViewInfoQuery } from '$typings/graphql-codegen';
import { Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import React, { memo, FC } from 'react';
import { getUserFeatures } from '../../../config/sessionService';

interface IExportJobFieldsCardProps {
  exportJob?: ExportJobViewInfoQuery['exportJob'];
  onEditClick: () => void;
}

const ExportJobFieldsCard: FC<IExportJobFieldsCardProps> = ({
  exportJob,
  onEditClick
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const features = getUserFeatures();
  return (
    <Card>
      <CardContent>
        <div className="flex justify-between">
          <CardHeader
            sx={{ paddingLeft: 0, paddingTop: 0 }}
            title={t('UI_ExportJobFields_Fields')}
            titleTypographyProps={{ fontSize: '1.3rem' }}
          />
          {features.exportManagement.write && (
            <Icon onClick={onEditClick} name={'fa fa-pencil'} />
          )}
        </div>

        {exportJob ? (
          <>
            {exportJob?.fields
              ?.sort((a, b) => a!.order - b!.order)
              .map((field, index) => (
                <p className="text-l" key={index}>
                  {index + 1}. {field?.name}
                </p>
              ))}
          </>
        ) : (
          <>
            <>
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton key={index} />
              ))}
            </>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(ExportJobFieldsCard);
