import React, { memo, FC, ReactElement } from 'react';
import Dropdown from '../dropdown/dropdown.react';
import { ChannelTypes } from '../../../config';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ChannelTypes as ChannelTypesEnum } from '../../../interfaces/enums/channelTypes';
import { ensureNumber } from '$lib/numberHelpers';

interface IChannelTypeDropdownProps {
  selected?: string;
  topElement?: string | boolean | 'IF_QUERY';
  itemClicked?: (channelType?: string) => void;
  error?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  replaceTopElement?: (query: string) => ReactElement;
}

interface IChannelType {
  id?: string;
  displayName?: string;
}

const ChannelTypeDropdown: FC<IChannelTypeDropdownProps> = ({
  selected,
  topElement,
  itemClicked,
  error,
  className,
  placeholder,
  disabled,
  replaceTopElement
}) => {
  const [t] = useCaseInsensitiveTranslation();
  return (
    <Dropdown<IChannelType>
      className={className}
      displayPath="displayName"
      valuePath="id"
      items={Object.keys(ChannelTypes).map(channelType => {
        return {
          displayName: t(
            ChannelTypes[ensureNumber(channelType) as ChannelTypesEnum]
          ),
          id: channelType
        };
      })}
      selected={selected}
      itemClicked={params =>
        itemClicked && itemClicked(params?.value as string)
      }
      topElement={topElement}
      placeholder={placeholder}
      error={error}
      disabled={disabled}
      replaceTopElement={replaceTopElement}
    />
  );
};

export default memo(ChannelTypeDropdown);
