import GridCell from '$components/grid/react-grid/cell/grid-cell';
import Grid from '$components/grid/react-grid/grid.react';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  AllExportJobsDocument,
  AllExportJobsQuery,
  SortDirection
} from '$typings/graphql-codegen';
import React, { memo, FC, useState, useMemo } from 'react';
import { makeSortedData } from '$pages/reports/makeSortedData';
import { makeSortWarningData } from '../helpers/export-helpers';
import Icon from '$components/icons/icon/icon.react';
import { isSomething } from '$lib/helpers';
import { ensureDate, formatDateTime } from '$lib/dateHelpers';

type ExportJobsListSortColumnKeys =
  | 'warning'
  | 'enabled'
  | 'name'
  | 'exportTypeEnum'
  | 'scheduleType'
  | 'lastRun'
  | 'notRunRecently';

type ExportJobsListSortValues = {
  sortColumnKey: ExportJobsListSortColumnKeys;
  sortDirection: SortDirection;
};

type SortOutputType = AllExportJobsQuery['exportJobs'];

const sortExportJobsList = (
  rows: Array<AllExportJobsQuery['exportJobs'][0] | undefined | null>,
  sortDirection: SortDirection,
  sortColumnKey: ExportJobsListSortColumnKeys
): SortOutputType | undefined => {
  if (!rows) return rows;
  const saneRows: SortOutputType = rows.filter(
    row => row !== undefined && row !== null
  ) as SortOutputType;
  const direction = sortDirection === SortDirection.Asc ? 'asc' : 'desc';

  if (sortColumnKey == 'warning') {
    return makeSortWarningData(saneRows, direction, sortColumnKey) as
      | SortOutputType
      | undefined;
  }

  return makeSortedData(saneRows, direction, sortColumnKey) as
    | SortOutputType
    | undefined;
};

interface IExportListGridProps {
  onJobClick: (jobId: string) => void;
  filter?: string;
}

const ExportListGrid: FC<IExportListGridProps> = ({ onJobClick, filter }) => {
  const [t] = useCaseInsensitiveTranslation();

  const [sortValues, setSortValues] = useState<ExportJobsListSortValues>({
    sortColumnKey: 'name',
    sortDirection: SortDirection.Asc
  });

  const data = useGridData(
    AllExportJobsDocument,
    {},
    data => data?.exportJobs.length,
    data => data.exportJobs
  );

  const filteredData = useMemo(() => {
    const searchFor = filter?.toLowerCase();
    return searchFor
      ? data?.data?.filter(
          e =>
            (e.name && e.name.toLowerCase().indexOf(searchFor) >= 0) ||
            (e.contact && e.contact.toLowerCase().indexOf(searchFor) >= 0) ||
            (e.description &&
              e.description.toLowerCase().indexOf(searchFor) >= 0)
        ) ?? []
      : data.data;
  }, [data, filter]);

  const orderedData = {
    ...data,
    data: filteredData
      ? sortExportJobsList(
          filteredData,
          sortValues.sortDirection,
          sortValues.sortColumnKey
        )
      : undefined
  };

  const exportTypes = (exportTypeEnum: number) => {
    switch (exportTypeEnum) {
      case 0:
        return 'Snapshot';
      case 1:
        return 'Full';
      case 2:
        return 'Shell Stocklevels';
      case 3:
        return 'Shell Deliveries';
      case 4:
        return 'Delivery tickets email';
      case 5:
        return 'IDS/Q8 CSV deliveries';
      case 6:
        return 'Tank level at midnight';
      case 7:
        return 'Welsh Water HSEQ reports';
      default:
        return 'unknown';
    }
  };

  const rowLink = (row: AllExportJobsQuery['exportJobs'][0]) => {
    if (!row) return;
    return `export/${row.exportJobId}`;
  };

  return (
    <Grid
      loading={data.isRevalidating}
      className="grow"
      name={'export-jobs-grid'}
      items={orderedData}
      sortedColumnKey={sortValues.sortColumnKey}
      sortedDirection={sortValues?.sortDirection}
      sortChanged={(sortByColumn, sortDirection) =>
        setSortValues({
          sortColumnKey: sortByColumn,
          sortDirection: sortDirection
        })
      }
      onRowClick={row => () => onJobClick(row.exportJobId!.toString())}
      rowLink={rowLink}
      columns={[
        {
          columnTitle: 'UI_Admin_ExportJob_Warning',
          columnKey: 'warning',
          initialWidth: 100,
          render: (job, width) => (
            <GridCell width={width}>
              {job.enabled && job.notRunRecently && (
                <Icon name="fa-exclamation-triangle" />
              )}
            </GridCell>
          )
        },
        {
          columnTitle: 'UI_Admin_ExportJob_List_Enabled',
          columnKey: 'enabled',
          initialWidth: 100,
          render: (job, width) => (
            <GridCell width={width}>
              {job.enabled && <Icon name="fa fa-check" />}
            </GridCell>
          )
        },
        {
          columnTitle: 'UI_Admin_ExportJob_List_Name',
          columnKey: 'name',
          initialWidth: 'auto',
          render: (job, width) => <GridCell width={width}>{job.name}</GridCell>
        },
        {
          columnTitle: 'UI_Admin_ExportJob_List_Type',
          columnKey: 'exportTypeEnum',
          initialWidth: 200,
          render: (job, width) => (
            <GridCell width={width}>{exportTypes(job.exportTypeEnum)}</GridCell>
          )
        },
        {
          columnTitle: 'UI_Admin_ExportJob_List_Schedule',
          columnKey: 'scheduleType',
          initialWidth: 200,
          render: (job, width) => (
            <GridCell width={width}>
              {isSomething(job.repeatMinutes) ? (
                `${t('UI_Admin_ExportJob_Schedule_Repeat_TemplateString', {
                  interval: job.repeatMinutes
                })}`
              ) : (
                <>{job.fixedSchedule}</>
              )}
            </GridCell>
          )
        },
        {
          columnTitle: 'UI_Admin_ExportJob_List_LastRun',
          columnKey: 'lastRun',
          initialWidth: 200,
          render: (job, width) => (
            <GridCell width={width}>
              {job.lastRun &&
                formatDateTime(
                  ensureDate(job.lastRun!),
                  'dd.MM.yyyy HH:mm',
                  undefined
                )}
            </GridCell>
          )
        },
        {
          columnTitle: 'UI_Admin_ExportJob_List_LastResult',
          columnKey: 'notRunRecently',
          initialWidth: 200,
          render: (job, width) => (
            <GridCell width={width}>{job.lastResult}</GridCell>
          )
        }
      ]}
    />
  );
};

export default memo(ExportListGrid);
