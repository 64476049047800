import Icon from '$components/icons/icon/icon.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import {
  ExportJobViewInfoQuery,
  UpdateExportFieldsDocument
} from '$typings/graphql-codegen';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import React, { memo, FC, useState, useEffect } from 'react';

interface IExportJobFieldsEditCardProps {
  exportJob?: ExportJobViewInfoQuery['exportJob'];
  exportFields: ExportJobViewInfoQuery['exportFields'];
  onSaveSuccess: () => void;
  onCancel: () => void;
}

interface IField {
  exportFieldId: number | null;
  field: number;
  order?: number;
}

const ExportJobFieldsEditCard: FC<IExportJobFieldsEditCardProps> = ({
  exportJob,
  exportFields,
  onSaveSuccess,
  onCancel
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedFields, setSelectedFields] = useState<IField[]>([]);
  const [unselectedFields, setUnselectedFields] = useState<IField[]>([]);
  const [error, setError] = useState<string | null>();

  const getFieldName = (fieldId: number) => {
    return exportFields?.find(f => f?.field === fieldId)?.name;
  };

  useEffect(() => {
    const unselectedFieldsTemp: IField[] = (exportFields || [])
      .map(field => {
        let selectedField = exportJob?.fields?.find(
          f => f?.field === field?.field
        );
        if (!selectedField) {
          return {
            field: field?.field,
            exportFieldId: null
          };
        }
        return null;
      })
      .filter(field => field !== null) as IField[];

    setUnselectedFields(unselectedFieldsTemp);

    const selectedFieldsTemp: IField[] = (exportJob?.fields || [])
      .sort((a, b) => a!.order - b!.order)
      .map(field => {
        return {
          exportFieldId: field?.exportFieldId ?? null,
          field: field!.field!
        };
      });

    setSelectedFields(selectedFieldsTemp);
  }, [exportFields, exportJob]);

  const moveFieldUp = (index: number): void => {
    if (index > 0) {
      const newSelectedFields = [...selectedFields];
      [newSelectedFields[index - 1], newSelectedFields[index]] = [
        newSelectedFields[index],
        newSelectedFields[index - 1]
      ];
      setSelectedFields(newSelectedFields);
    }
  };

  const moveFieldDown = (index: number): void => {
    if (index < selectedFields.length - 1) {
      const newSelectedFields = [...selectedFields];
      [newSelectedFields[index + 1], newSelectedFields[index]] = [
        newSelectedFields[index],
        newSelectedFields[index + 1]
      ];
      setSelectedFields(newSelectedFields);
    }
  };

  const addField = (field: IField): void => {
    setSelectedFields(prevFields => [...prevFields, field]);
    setUnselectedFields(prevFields =>
      prevFields.filter(f => f.field !== field.field)
    );
  };

  const removeField = (field: IField): void => {
    setSelectedFields(prevFields =>
      prevFields.filter(f => f.field !== field.field)
    );
    setUnselectedFields(prevFields => [...prevFields, field]);
  };

  const handleSubmit = async () => {
    setError(null);
    selectedFields.forEach((field, index) => {
      field.order = index;
    });
    const adds = selectedFields.filter(f => f.exportFieldId == null);
    const updates = selectedFields.filter(f => f.exportFieldId != null);
    const deletes = unselectedFields.filter(f => f.exportFieldId != null);

    await mutate(
      UpdateExportFieldsDocument,
      {
        updateData: {
          adds: adds.map(add => ({
            exportJobId: ensureNumber(exportJob!.exportJobId!),
            field: add.field,
            order: add.order!
          })),
          deletes: deletes.map(del => del.exportFieldId!),
          updates: updates.map(update => ({
            exportFieldId: update.exportFieldId,
            order: update.order!
          }))
        }
      },
      true,
      _ => {
        onSaveSuccess();
      },
      () => {
        setError(t('UI_Common_Error_Save_Item_Failed'));
      }
    );
  };

  return (
    <Card>
      <CardContent>
        <CardHeader
          sx={{ paddingLeft: 0, paddingTop: 0 }}
          title={t('UI_ExportJobFields_Edit_Fields')}
          titleTypographyProps={{ fontSize: '1.3rem' }}
        />
        <div className="flex flex-col">
          <h1 className="text-l">{t('UI_ExportJobFields_Selected_Fields')}</h1>
          {selectedFields.map((field, index) => (
            <div key={field.field}>
              {index === selectedFields.length - 1 ? (
                <Icon name="fa fa-ellipsis-h" />
              ) : (
                <Icon
                  name="fa fa-caret-down"
                  onClick={() => moveFieldDown(index)}
                />
              )}

              {index === 0 ? (
                <Icon className="ml-3" name="fa fa-ellipsis-h" />
              ) : (
                <Icon
                  className="ml-3"
                  name="fa fa-caret-up"
                  onClick={() => moveFieldUp(index)}
                />
              )}

              <Icon
                className="ml-3"
                name="fa fa-times"
                onClick={() => removeField(field)}
              />

              <span className="text-l ml-3">{getFieldName(field.field)}</span>
            </div>
          ))}
        </div>
        <div>
          <h1 className="text-l">
            {t('UI_ExportJobFields_Unselected_Fields')}
          </h1>
          {unselectedFields.map(field => (
            <div key={field.field} className="unselected-field">
              <Icon name="fa fa-plus" onClick={() => addField(field)} />
              <span className="pl-3 text-l">{getFieldName(field.field)}</span>
            </div>
          ))}
        </div>

        {error && <ErrorText>{error}</ErrorText>}
      </CardContent>

      <CardContent className="flex justify-between">
        <Button onClick={onCancel} variant="outlined">
          {t('UI_Common_Cancel')}
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {t('UI_Common_Save')}
        </Button>
      </CardContent>
    </Card>
  );
};

export default memo(ExportJobFieldsEditCard);
