import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Skeleton
} from '@mui/material';
import React, { memo, FC } from 'react';
import { ExportJobViewInfoQuery } from '$typings/graphql-codegen';
import Pill from '$components/pill/pill.react';
import { ChannelTypes as ChannelTypesEnum } from '$interfaces/enums';
import { ChannelTypes } from '../../../config';
import Icon from '$components/icons/icon/icon.react';
import { getUserFeatures } from '../../../config/sessionService';
import { ensureNumber } from '$lib/numberHelpers';

interface IExportJobDetailsCardProps {
  exportJob?: ExportJobViewInfoQuery['exportJob'];
  onEditClick: () => void;
}

const ExportJobDetailsCard: FC<IExportJobDetailsCardProps> = ({
  exportJob,
  onEditClick
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const features = getUserFeatures();

  const exportTypes = (exportTypeEnum: number) => {
    switch (exportTypeEnum) {
      case 0:
        return 'Snapshot';
      case 1:
        return 'Full';
      case 2:
        return 'Shell Stocklevels';
      case 3:
        return 'Shell Deliveries';
      case 4:
        return 'Delivery tickets email';
      case 5:
        return 'IDS/Q8 CSV deliveries';
      case 6:
        return 'Tank level at midnight';
      case 7:
        return 'Welsh Water HSEQ reports';
      default:
        return 'unknown';
    }
  };

  const hidePassword = (destination?: string | null) => {
    try {
      if (!destination) return '';
      const parts = destination.split('|');
      let partsWithoutPassword = new Array<string>();

      parts.forEach(dest => {
        let destWithoutProtocol = dest.replace('sftp://', '');
        destWithoutProtocol = destWithoutProtocol.replace('ftp://', '');
        destWithoutProtocol = destWithoutProtocol.replace('mailto://', '');
        destWithoutProtocol = destWithoutProtocol.replace('mailto:', '');

        //Does part contain password?
        if (
          destWithoutProtocol.indexOf('@') > 0 &&
          destWithoutProtocol.indexOf(':') > 0
        ) {
          const start = destWithoutProtocol.indexOf(':');
          const end = destWithoutProtocol.indexOf('@');
          const password = destWithoutProtocol.substring(start + 1, end);

          partsWithoutPassword.push(dest.replace(password, '*******'));
        } else partsWithoutPassword.push(dest);
      });

      return partsWithoutPassword.join(' | ');
    } catch {}

    return destination;
  };

  return (
    <Card>
      <CardContent>
        <div className="flex justify-between">
          <CardHeader
            sx={{ paddingLeft: 0, paddingTop: 0 }}
            titleTypographyProps={{ fontSize: '1.3rem' }}
            title={t('UI_ExportJobDetails_View_Job')}
          />
          {features.exportManagement.write && (
            <Icon onClick={onEditClick} name={'fa fa-pencil'} />
          )}
        </div>
        {exportJob ? (
          <SimpleTable layout="fixed">
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Edit_Name')}
              </SimpleTableCell>
              <SimpleTableCell>{exportJob.name}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Edit_Destination')}
              </SimpleTableCell>
              <SimpleTableCell>
                {hidePassword(exportJob.destination)}
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Edit_FileNamePattern')}
              </SimpleTableCell>
              <SimpleTableCell>
                {exportJob.destinationFilePattern}
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Enabled')}
              </SimpleTableCell>
              <SimpleTableCell>
                <Checkbox disabled checked={exportJob.enabled ?? false} />
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_IncludeHeders')}
              </SimpleTableCell>
              <SimpleTableCell>
                <Checkbox
                  disabled
                  checked={exportJob.includeHeaders ?? false}
                />
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_ExportType')}
              </SimpleTableCell>
              <SimpleTableCell>
                {exportTypes(exportJob.exportTypeEnum)}
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Delimiter')}
              </SimpleTableCell>
              <SimpleTableCell>{exportJob.delimiterChar}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_DateFormat')}
              </SimpleTableCell>
              <SimpleTableCell>{exportJob.dateFormat}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Schedule_TimesOfDay')}
              </SimpleTableCell>
              <SimpleTableCell>
                {exportJob.fixedSchedule} {exportJob.fixedSchedule && '(UTC)'}
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Schedule_Repeat_Every')}
              </SimpleTableCell>
              <SimpleTableCell>{exportJob.repeatMinutes}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Description')}
              </SimpleTableCell>
              <SimpleTableCell>{exportJob.description}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Contact')}
              </SimpleTableCell>
              <SimpleTableCell>{exportJob.contact}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_ChannelTypes')}
              </SimpleTableCell>
              <SimpleTableCell className="flex gap-1">
                <div
                  style={{ flexWrap: 'wrap' }}
                  className="flex w-full gap-2 grow"
                >
                  {exportJob.channelTypes?.map(channelType => (
                    <Pill key={channelType} className="inline-block">
                      {t(
                        ChannelTypes[
                          ensureNumber(channelType) as ChannelTypesEnum
                        ]
                      )}
                    </Pill>
                  ))}
                </div>
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ExportJob_Customers')}
              </SimpleTableCell>
              <SimpleTableCell className="flex gap-1">
                <div
                  style={{ flexWrap: 'wrap' }}
                  className="flex w-full gap-2 grow"
                >
                  {exportJob.customers?.map(customer => (
                    <Pill key={customer.customerId} className="inline-block">
                      {customer.name}
                    </Pill>
                  ))}
                </div>
              </SimpleTableCell>
            </SimpleTableRow>
          </SimpleTable>
        ) : (
          <SimpleTable>
            {Array.from({ length: 10 }).map((_, index) => (
              <React.Fragment key={index}>
                <SimpleTableRow>
                  <SimpleTableCell>
                    <Skeleton />
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Skeleton />
                  </SimpleTableCell>
                </SimpleTableRow>
              </React.Fragment>
            ))}
          </SimpleTable>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(ExportJobDetailsCard);
