import './export-job-form.css';
import ChanneltypeDropdown from '$components/dropdowns/channeltype-dropdown/channeltype-dropdown';
import CustomerDropdown from '$components/dropdowns/customer-dropdown/customer-dropdown.react';
import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import ExportTypeDropdown from '$components/dropdowns/export-type-dropdown/export-type-dropdown';
import Icon from '$components/icons/icon/icon.react';
import Pill from '$components/pill/pill.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { ChannelTypes as ChannelTypesEnum } from '$interfaces/enums';
import { ChannelTypes } from '../../../config';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { mutate } from '$lib/hooks/fetch-utillities';
import { IGeneralFormError } from '$lib/hooks/reactHookFormHelper';
import { ensureNumber } from '$lib/numberHelpers';
import {
  ExportJobCustomerInput,
  ExportJobViewInfoQuery,
  UpsertExportJobDocument
} from '$typings/graphql-codegen';
import {
  Tooltip,
  IconButton,
  Checkbox,
  Button,
  FormControlLabel
} from '@mui/material';
import React, { memo, FC, useState, useEffect } from 'react';
import {
  Controller,
  FieldError,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import MuiTextField from '$components/textbox/mui-textfield.react';

interface ExportJobCreateFormType extends IGeneralFormError {
  exportJobId?: string;
  name: string;
  destination: string;
  destinationFilePattern: string;
  exportTypeEnum: string;
  enabled: boolean;
  delimiterChar: string;
  dateformat: string;
  includeHeaders: boolean;
  fixedSchedule: string | null;
  repeatMinutes: number | null;
  description: string;
  contact: string;
  channelTypes: number[];
  customers: ExportJobCustomerInput[];
}

interface IScheduleType {
  value: string;
  text: string;
}

const scheduleTypes: IScheduleType[] = [
  { value: 'repeat', text: 'Repeated' },
  { value: 'time', text: 'Timed' }
];

interface IExportJobFormProps {
  exportJob?: ExportJobViewInfoQuery['exportJob'];
  onUpsertSuccess: (exportJobId?: string) => void;
  onCancel: () => void;
  layout?: 'fixed' | 'auto';
}

const ExportJobForm: FC<IExportJobFormProps> = ({
  exportJob,
  onUpsertSuccess,
  onCancel,
  layout = 'auto'
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedChannelTypes, setSelectedChannelTypes] = useState([
    ...(exportJob?.channelTypes ?? [])
  ]);
  const [selectedCustomerTags, setSelectedCustomerTags] = useState<
    ExportJobCustomerInput[]
  >([...(exportJob?.customers ?? [])]);
  const [selectedScheduleType, setSelectedScheduleType] = useState<string>(
    'time'
  );

  const onRemoveChannelTypeClick = (channelType: number) => {
    const updateSelectedChannelTypes = selectedChannelTypes.filter(
      ct => ct !== channelType
    );
    setSelectedChannelTypes(updateSelectedChannelTypes);
  };

  const onRemoveCustomerClick = (customerId: string) => {
    const updatedCustomerTags = selectedCustomerTags.filter(
      ct => ct.customerId !== customerId
    );
    setSelectedCustomerTags(updatedCustomerTags);
  };

  useEffect(() => {
    setSelectedScheduleType(
      exportJob?.repeatMinutes != null ? 'repeat' : 'time'
    );
  }, [exportJob]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    control
  } = useForm<ExportJobCreateFormType>({
    values: {
      exportJobId: exportJob?.exportJobId ?? undefined,
      name: exportJob?.name ?? '',
      destination: exportJob?.destination ?? '',
      destinationFilePattern: exportJob?.destinationFilePattern ?? '',
      exportTypeEnum: exportJob?.exportTypeEnum.toString() ?? '',
      enabled: exportJob?.enabled ?? false,
      customers: exportJob?.customers ?? [],
      channelTypes: exportJob?.channelTypes ?? [],
      dateformat: exportJob?.dateFormat ?? '',
      delimiterChar: exportJob?.delimiterChar ?? '',
      description: exportJob?.description ?? '',
      includeHeaders: exportJob?.includeHeaders ?? false,
      fixedSchedule: exportJob?.fixedSchedule ?? '',
      repeatMinutes: exportJob?.repeatMinutes ?? null,
      contact: exportJob?.contact ?? '',
      formError: ''
    }
  });

  const onSubmit: SubmitHandler<ExportJobCreateFormType> = async data => {
    if (selectedScheduleType === 'repeat') data.fixedSchedule = '';
    else data.repeatMinutes = null;

    await mutate(
      UpsertExportJobDocument,
      {
        exportJobInput: {
          exportJobId: data.exportJobId,
          name: data.name,
          destination: data.destination,
          enabled: data.enabled,
          destinationFilePattern: data.destinationFilePattern,
          exportTypeEnum: ensureNumber(data.exportTypeEnum),
          delimiterChar: data.delimiterChar,
          dateFormat: data.dateformat,
          includeHeaders: data.includeHeaders,
          repeatMinutes: ensureNumber(data.repeatMinutes!),
          fixedSchedule: data.fixedSchedule,
          description: data.description,
          contact: data.contact,
          channelTypes: selectedChannelTypes.map(channelType =>
            ensureNumber(channelType)
          ),
          customers: selectedCustomerTags
        }
      },
      true,
      res => {
        onUpsertSuccess(res.upsertExportJob!);
      },
      () => {
        setError('formError', {
          type: 'value',
          message: t('UI_Common_Error_Save_Item_Failed')
        });
      }
    );
  };
  return (
    <form className="text-l" onSubmit={handleSubmit(onSubmit)}>
      <SimpleTable layout={layout}>
        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Name')}
          </SimpleTableCell>
          <SimpleTableCell>
            <MuiTextField
              {...register('name', {
                required: {
                  value: true,
                  message: t(
                    'ui_common_validationerror_requiredfield'
                  ) as string
                }
              })}
            />
            <ErrorText>{(errors.name as FieldError)?.message}</ErrorText>
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_Edit_Destination')}
          </SimpleTableCell>
          <SimpleTableCell className="flex flex-row items-center gap-2">
            <div className="grow">
              <MuiTextField {...register('destination')} />
            </div>
            <Tooltip
              placement="right"
              title={t('UI_Admin_ExportJob_Edit_DestinationHelp')}
            >
              <IconButton>
                <Icon name="fa fa-question" />
              </IconButton>
            </Tooltip>
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_Enabled')}
          </SimpleTableCell>
          <SimpleTableCell>
            <Controller
              name="enabled"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label={t('UI_Admin_ExportJob_Enabled')}
                  labelPlacement="end"
                  control={<Checkbox checked={value} onChange={onChange} />}
                />
              )}
            />
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_Edit_FileNamePattern')}
          </SimpleTableCell>
          <SimpleTableCell className="flex flex-row items-center gap-2">
            <div className="grow">
              <MuiTextField
                {...register('destinationFilePattern', {
                  required: {
                    value: true,
                    message: t(
                      'ui_common_validationerror_requiredfield'
                    ) as string
                  }
                })}
              />
              <ErrorText>
                {(errors.destinationFilePattern as FieldError)?.message}
              </ErrorText>
            </div>
            <Tooltip
              placement="right"
              title={t('UI_Admin_ExportJob_Edit_FileNamePatternHelp')}
            >
              <IconButton>
                <Icon name="fa fa-question" />
              </IconButton>
            </Tooltip>
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_ExportType')}
          </SimpleTableCell>
          <SimpleTableCell>
            <Controller
              control={control}
              name="exportTypeEnum"
              rules={{
                required: {
                  value: true,
                  message: t(
                    'ui_common_validationerror_requiredfield'
                  ) as string
                }
              }}
              render={({ field }) => (
                <ExportTypeDropdown
                  placeholder={t('UI_ExportJobForm_Select_ExportType')}
                  selected={field.value}
                  itemClicked={exportType => {
                    field.onChange(exportType);
                  }}
                />
              )}
            />
            <ErrorText>
              {(errors.exportTypeEnum as FieldError)?.message}
            </ErrorText>
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_Delimiter')}
          </SimpleTableCell>
          <SimpleTableCell>
            <MuiTextField {...register('delimiterChar')} />
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_DateFormat')}
          </SimpleTableCell>
          <SimpleTableCell className="flex flex-row items-center gap-2">
            <div className="grow">
              <MuiTextField {...register('dateformat')} />
            </div>
            <Tooltip
              placement="right"
              title={t('UI_Admin_ExportJob_DateFormatHelp')}
            >
              <IconButton>
                <Icon name="fa fa-question" />
              </IconButton>
            </Tooltip>
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_IncludeHeders')}
          </SimpleTableCell>
          <SimpleTableCell>
            <Controller
              name="includeHeaders"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label={t('UI_Admin_ExportJob_IncludeHeders')}
                  labelPlacement="end"
                  control={<Checkbox checked={value} onChange={onChange} />}
                />
              )}
            />
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_Schedule_Type')}
          </SimpleTableCell>
          <SimpleTableCell>
            <Dropdown<IScheduleType>
              items={scheduleTypes}
              displayPath="text"
              valuePath="value"
              selected={selectedScheduleType}
              itemClicked={scheduleType => {
                setSelectedScheduleType(scheduleType?.value as string);
              }}
            />
          </SimpleTableCell>
        </SimpleTableRow>

        {selectedScheduleType === 'time' ? (
          <SimpleTableRow>
            <SimpleTableCell className="vertical-align-middle">
              {t('UI_Admin_ExportJob_Schedule_TimesOfDay')}
            </SimpleTableCell>
            <SimpleTableCell className="flex flex-row items-center gap-2">
              <div>
                <MuiTextField
                  {...register('fixedSchedule', {
                    required: {
                      value: true,
                      message: t(
                        'ui_common_validationerror_requiredfield'
                      ) as string
                    }
                  })}
                />
                <ErrorText>
                  {(errors.fixedSchedule as FieldError)?.message}
                </ErrorText>
              </div>
              <p>(UTC)</p>
            </SimpleTableCell>
          </SimpleTableRow>
        ) : (
          <SimpleTableRow>
            <SimpleTableCell className="vertical-align-middle">
              {t('UI_Admin_ExportJob_Schedule_Repeat_Every')}
            </SimpleTableCell>
            <SimpleTableCell className="flex flex-row items-center gap-2">
              <div>
                <MuiTextField
                  {...register('repeatMinutes', {
                    required: {
                      value: true,
                      message: t(
                        'ui_common_validationerror_requiredfield'
                      ) as string
                    }
                  })}
                />
                <ErrorText>
                  {(errors.repeatMinutes as FieldError)?.message}
                </ErrorText>
              </div>
              <p>minutes</p>
            </SimpleTableCell>
          </SimpleTableRow>
        )}

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_Description')}
          </SimpleTableCell>
          <SimpleTableCell>
            <MuiTextField {...register('description')} />
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_Contact')}
          </SimpleTableCell>
          <SimpleTableCell>
            <MuiTextField {...register('contact')} />
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_ChannelTypes')}
          </SimpleTableCell>
          <SimpleTableCell>
            <Controller
              control={control}
              name="channelTypes"
              render={({}) => (
                <ChanneltypeDropdown
                  placeholder={t('UI_ExportJobForm_Select_ChannelType')}
                  itemClicked={channelType => {
                    if (
                      !selectedChannelTypes.find(
                        ct => ct == ensureNumber(channelType!)
                      )
                    ) {
                      selectedChannelTypes.push(ensureNumber(channelType!));
                      setSelectedChannelTypes([...selectedChannelTypes]);
                    }
                  }}
                />
              )}
            />

            <div
              style={{ flexWrap: 'wrap' }}
              className="flex w-full gap-2 grow pt-2"
            >
              {selectedChannelTypes.map(channelType => (
                <Pill key={channelType}>
                  {t(
                    ChannelTypes[ensureNumber(channelType) as ChannelTypesEnum]
                  )}

                  <Icon
                    onClick={() => onRemoveChannelTypeClick(channelType)}
                    className="ml-3"
                    name="fa fa-times"
                  />
                </Pill>
              ))}
            </div>
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ExportJob_Customers')}
          </SimpleTableCell>
          <SimpleTableCell>
            <Controller
              control={control}
              name="customers"
              render={({}) => (
                <CustomerDropdown
                  placeholder={t('UI_ExportJobForm_Select_Customer')}
                  itemClicked={({ customerId, name }) => {
                    if (
                      !selectedCustomerTags.find(
                        ct => ct.customerId == customerId
                      )
                    ) {
                      selectedCustomerTags.push({
                        name: name!,
                        customerId: customerId!
                      });
                      setSelectedCustomerTags([...selectedCustomerTags]);
                    }
                  }}
                />
              )}
            />

            <div
              style={{ flexWrap: 'wrap' }}
              className="flex w-full gap-2 grow pt-2"
            >
              {selectedCustomerTags.map(customerTag => (
                <Pill key={customerTag.customerId}>
                  {customerTag.name}
                  <Icon
                    onClick={() =>
                      onRemoveCustomerClick(customerTag.customerId)
                    }
                    className="ml-3"
                    name="fa fa-times"
                  />
                </Pill>
              ))}
            </div>
          </SimpleTableCell>
        </SimpleTableRow>
      </SimpleTable>
      <div className="flex justify-between mar-ts">
        <Button onClick={onCancel} variant="outlined" color="primary">
          {t('UI_Common_Cancel')}
        </Button>
        <Button
          startIcon={isSubmitting && <Icon name="fa-spinner fa-pulse" />}
          variant="contained"
          type="submit"
        >
          {t('UI_Common_SaveChanges')}
        </Button>
      </div>
      <ErrorText>{(errors.formError as FieldError)?.message}</ErrorText>
    </form>
  );
};

export default memo(ExportJobForm);
