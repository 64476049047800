import { Button, Card, CardContent, CardHeader } from '@mui/material';
import React, { memo, FC, useState } from 'react';
import Icon from '$components/icons/icon/icon.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import ExportJobCreateModal from './export-job-create-modal';
import { getUserFeatures } from '../../../config/sessionService';
import { useNavigate } from 'react-router-dom';
import ExportListGrid from './export-list-grid';
import MuiTextField from '$components/textbox/mui-textfield.react';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';

interface IExportListCardProps {}

const ExportListCard: FC<IExportListCardProps> = () => {
  const [t] = useCaseInsensitiveTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const features = getUserFeatures();
  const [filter, setFilter] = useState<string>('');
  const nav = useNavigate();

  return (
    <Card className="h-full flex flex-col">
      <CardContent className="flex flex-col gap-4">
        <div className="flex justify-between ">
          <CardHeader
            sx={{ paddingLeft: 0, paddingTop: 0 }}
            title={t('UI_Admin_ExportJob_List_Title')}
            titleTypographyProps={{ fontSize: '1.3rem' }}
          />
          {features.exportManagement.write && (
            <Button onClick={handleOpen} variant="outlined">
              {t('UI_Admin_ExportJob_Add_Export_Job')}
            </Button>
          )}
        </div>
        <div style={{ width: '30%' }}>
          <MuiTextField
            value={filter}
            onChange={e => setFilter(e.target.value)}
            placeholder="Search"
            icon={<Icon name="fa-search" />}
            iconEnd={
              filter && (
                <ClickableIcon
                  name="fa-times"
                  onClick={() => setFilter && setFilter('')}
                />
              )
            }
          />
        </div>
      </CardContent>
      <CardContent sx={{ padding: 0 }} className="flex grow">
        <ExportListGrid
          filter={filter}
          onJobClick={(jobId: string) =>
            nav(`/export/${jobId}`, { replace: true })
          }
        />
      </CardContent>

      <ExportJobCreateModal open={open} setOpen={setOpen} />
    </Card>
  );
};

export default memo(ExportListCard);
