import './export.css';
import { ReactWrapper } from '$pages/common/react-wrapper';
import ExportComponent, { IExportProps } from './export.react';
import { NotificationService } from '$services/notificationService';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class ExportReactWrapper extends ReactWrapper<IExportProps> {
  constructor(notificationService: NotificationService) {
    super(ExportComponent, params => ({
      aureliaNotificationService: notificationService,
      params: params
    }));
  }
}
