import React, { FC, memo } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom';
import ExportDetails from './export-details/export-details';
import { NotificationService } from '$services/notificationService';
import ExportListCard from './components/export-list-card';

export interface IExportProps {
  aureliaNotificationService: NotificationService;
}

const ExportComponent: FC<IExportProps> = ({ aureliaNotificationService }) => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<Navigate to="export" />} path={'export'}>
        <Route
          index
          path={'/export'}
          element={
            <div className="p-4 grow">
              <ExportListCard />
            </div>
          }
        />
        <Route
          path={':exportId'}
          element={
            <ExportDetails
              aureliaNotificationService={aureliaNotificationService}
            />
          }
        />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default memo(ExportComponent);
